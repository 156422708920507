import React, {useState} from 'react';
import { BallTriangle } from  'react-loader-spinner';
import {Box, Grid, TextField, Button, Container } from '@mui/material';
import DataTable from 'react-data-table-component';
import axios from "axios";
import PopulateBulkIndex from "../Component/PopulateBulkIndex"


const submitButton = {
    paddingTop: "14px",
    paddingBottom: "14px",
    display: "block",
    width: "130px",
    marginTop: "15px"
}

function BulkPageIndexChecker() {


    const [loading, setLoading] = useState();
    const [bulkIndexChecker, setBulkIndexChecker] = useState([]);
    const [messageerror, setMessageError] = useState(false);
    const [dataCopyToClip, setDataCopyToClip] = useState();
    const [selectedQuestionData, setSelectedQuestionData] = useState();
    // const [inputValue, setInputValue] = useState();
    // const [userApiKey, setUserApiKey] = useState();

// f97fe239d5dc18989f8cf2732b0eaf02

    const [data, setData] = useState({    
      inputValue: "",
      userApiKey: "",
    })


    const { inputValue, userApiKey } = data

    const inputhandleChange = e =>
      setData({ ...data, [e.target.name]: e.target.value })


console.log(inputValue, userApiKey)
  
    var res = '';

   
   
    const  handleSubmit = async (e) => {
        e.preventDefault();
       
         await axios.post("https://kwintent-index.herokuapp.com", {inputValue, userApiKey} , {
            headers: {'Access-Control-Allow-Origin': '*' , "Content-Type": "application/json" }
          })
            .then((response) => {
            console.log(response.data);
              const resData = response.data
              
             res = resData;
           
            const betterJson = `[${res.replace(/}{/g,'},{')}]`;
            console.log(betterJson);
          const myData =  JSON.parse(betterJson)
        console.log(myData)
          setBulkIndexChecker(myData)      


            // setBulkIndexChecker(response.data);
            setLoading(false);
            setMessageError(false)
              
            }).catch((error) => {
            
              // console.log(error)
              setMessageError(true)
              setLoading(false);
            }) 
        } 

        // console.log(bulkIndexChecker);

        const customStyles = {
            headCells: {
              style: {
                backgroundColor: 'rgb(255, 0, 223)',
                color: '#FFF',
                fontWeight: 'bold',
                fontSize: '17px',
              },
            },
            cells: {
              style: {
                fontSize: '14px',
              },
            },
          };


          const columns = [
            {
            name: '# ',
            cell: (row, index) => index + 1,
            width: '49px',
            // sortFunction: caseInsensitiveSort
            },
            {
                name: 'Website url',
                selector: row => row.url,
                width: '687px',
                // sortable: true,
             
            },
            {
                name: 'Pages Index',
                selector: row => row.indexation,
                sortable: true,
                // width: '400px',
                
            }
          ];



          const handleChange = ({ selectedRows }) => {
            // You can set state or dispatch with something like Redux so we can use the retrieved data
           
          
            
            var arr1 = selectedRows.map(function(item){
              return [item.url, item.indexation];
          });
          
          
          var arr2 = selectedRows.map(function(item){
            return item.url ;
          });
          
          setDataCopyToClip(arr2.join('\n'))
          
          setSelectedQuestionData(arr1)
          };  


          if(selectedQuestionData !== undefined && selectedQuestionData.length !== 0){

            var lengthData = selectedQuestionData.length
            }


  return (
    <Container maxWidth="xl" className="bulkindexchecker"> 
    <Box >
   
        <Grid  mt={8}  alignItems="center"justify="center"  >
       
        <form autoComplete="off" onSubmit={handleSubmit}>
      
          <Grid container  item spacing={2}>
            <Grid item xs={12} >
            <TextField id="outlined-multiline-static" required style ={{width: '100%'}} label="one link per line" multiline rows={10}  variant="outlined" name="inputValue" onChange={inputhandleChange} value={inputValue} />
            </Grid>
         
            <Grid item xs={12} align="center" >
            <TextField type="password"  required style ={{width: '100%'}} label="Scraper Api Key"   variant="outlined" name="userApiKey" onChange={inputhandleChange} value={userApiKey} />
            
                     <Button variant="contained" color="primary"  type="submit" style={submitButton} onClick={() => setLoading(true)} >SUBMIT</Button>
              </Grid>
          </Grid>
        
        </form>
       
       {bulkIndexChecker.length === 0 ? <p style={{marginTop: "10px"}}>PS: If you don't have scraper api key <a href="https://www.scraperapi.com/pricing?fp_ref=keywordintent" target="_blank" rel="noopener noreferrer nofollow">click here</a> for 5,000 free API Credits. No credit card is required for the free trial.</p> : null}

{/* Form Div end */}
        {messageerror && 
        <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '60vh' }} >
         <h2 className="errorHeading">Oops! Something went wrong</h2>
         <Box className="errorMessage" mt={5}>Please take a screenshot of <a href="https://screenful.com/how-to-open-the-browser-console" target="_blank" rel="noopener noreferrer" className="errorMessage erroranchor" >browser developer console</a> and send this to our live chat support agent</Box>
          
        </Grid>
        }


        {loading ? <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '80vh' }} >
        <Grid item md={7}>
        <BallTriangle color="#FF00DF" ariaLabel="ball-triangle-loading" />
        </Grid>   
        <Grid item md={5}>
        </Grid> 
        </Grid> 
      
      : null }
 

<Box mt={7}>


{  bulkIndexChecker !== undefined && bulkIndexChecker.length !== 0 ?

<PopulateBulkIndex populateData={selectedQuestionData} selectedRowsData={lengthData} copyToClipboard={dataCopyToClip} />
  : null
}


<DataTable columns={columns} data={bulkIndexChecker} customStyles={customStyles} selectableRows onSelectedRowsChange={handleChange} />
</Box>


        </Grid>
      
        </Box> 
       </Container> 
  
  )
}

export default BulkPageIndexChecker;