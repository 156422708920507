import React from 'react';
import {Box, Grid, Typography, Button, Container } from '@mui/material';
import { HashLink } from 'react-router-hash-link';


function Pricing() {
  return (
    <>
  
    <section className='mainHeroSectionMargin'>
    <div className="hero">
    <Container>
      
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box  sx={{ maxWidth: 880 }}>
    <Box pt={8} pb={6}>
	  <p className="pricingMainParagraph">An Easy Way To Discover Keywords, Topics and Angles Instantly Without Guessing </p>
    </Box>
    
  <h1 className="pricingMainHeading">New Keyword Research Tool For SEO's, Media Buyers & Content Marketers</h1>
  <Box  pt={5}>
  <p className="pricingMainParagraph">You'll know you have stumbled upon the right tool, when you realize that your content almost writes itself with the brand new and unique Keyword Intent tool - So that provides topics, ideas, relevant keywords and angles that you'd have to dig deep to find on other keyword tools, saving you time, frustration and money.</p>
  </Box>
  <Box pt={5} pb={3}>

  {/* <a className="buyNow" href="#"><span data-attr="BUY">Buy</span><span data-attr="NOW">Now</span></a> */}
  <a href="https://adnan.zaxaa.com/o/46738399960783/1"> <Button variant="contained" className="buyButton sizeBigger" align="center">CLICK HERE NOW<br />Get Instant Access</Button></a>

  </Box>
  </Box>
  </Box>
  </Container>
</div>
    </section>
    

    <Box p={4}  className="borderBottom">
    <Grid container spacing={2}>
  <Grid item xs={12} sm={6} md={3}>
  <Typography variant="h6" component="h6" align="center"  className="borderRight" ><HashLink to="/pricing#whatWeDo" className="prices" > WHAT WE DO</HashLink>  </Typography>
  </Grid>
  <Grid item xs={12} sm={6} md={3}>
  <Typography variant="h6" component="h6" align="center"  className="borderRight"><HashLink to="/pricing#whatWeDoIt" className="prices" >WHY WE DO IT</HashLink>   </Typography>
  </Grid>
  <Grid item xs={12} sm={6} md={3}>
  <Typography variant="h6" component="h6" align="center"  className="borderRight"><HashLink to="/pricing#testimonial" className="prices" >TESTIMONIALS</HashLink> </Typography>
   </Grid>
   <Grid item xs={12} sm={6} md={3}>
   <Typography variant="h6" component="h6" align="center" ><HashLink to="/pricing#prices" className="prices" >PRICES</HashLink>  </Typography>
   </Grid>
</Grid>
    </Box>


    <Container>

    <section id="whatWeDo">
      <Box pt={8}>
    <Grid container spacing={1}>
  <Grid item xs={12} md={6} px={3}>
  <Typography variant="h4" component="h4" gutterBottom className="headingMainWhat headingMainWhatMobile">Keyword research may not always be the fun part of creating content - but try succeeding without it.
 </Typography>
    <Box pt={6}>
 <p className="pricingMainParagraph headingMainWhat headingMainWhatA">
 You are struggling to find new topics and ideas for your next video, your content marketing, SEO or even your Ad campaigns.
 </p> <br />
 <p  className="pricingMainParagraph headingMainWhat headingMainWhatA">You need to spend hours researching the best keywords and writing content around them or you don’t have time to read all the news, blogs and social media posts every day.
<br /><br />Use Keyword Intent, our new and unique keyword research tool, to find hot topics, new angles and  the perfect keywords for your content in seconds!</p>
 </Box>

  </Grid>
  <Grid item xs={12} md={6}>
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
  <img
        src="https://anthonyhayes.me/wp-content/uploads/38163144_flat-stress-setcropped-1.webp"
        alt="main" width="520px" height="500px" className="stressImage"
      />
      </Box>
  </Grid>
</Grid>
</Box>
    </section>



    <section id="whatWeDoIt">
      <Box pt={17}>
    <Grid container spacing={1}>
  <Grid item xs={12} md={6} lg={4}>
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
  <img
        src="https://anthonyhayes.me/wp-content/uploads/35502846_graph-1.jpg"
        alt="main" width="250px" height="250px"
      />
</Box>
<Typography py={4} variant="h6" component="h6" gutterBottom className="headingMainWhat">Your Content Almost Writes Itself</Typography>

 <p className="pricingMainParagraph headingMainWhat headingMainWhatA">
 The Keyword Intent semantic algorithm provides relevant topic and sub-topic angles and ideas that create an easy content flow and outline.
 </p> 

  
  </Grid>



  <Grid item xs={12} md={6} lg={4}>
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
  <img
        src="https://anthonyhayes.me/wp-content/uploads/35505622_education-1.jpg"
        alt="main" width="250px" height="250px"
      />
</Box>
<Typography py={3} variant="h6" component="h6" gutterBottom className="headingMainWhat">Learn With Bitesize Step-by-Step Live Demo Tutorials</Typography>

 <p className="pricingMainParagraph headingMainWhat headingMainWhatA">
 Short and 'to the point' bitesize video tutorials, that follow the flow of using Keyword Intent quickly with a live demo example for topic optimization.
 </p> 


  </Grid>


  <Grid item xs={12} md={6} lg={4}>
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
  <img
        src="https://anthonyhayes.me/wp-content/uploads/35674518_dollar-graph-1.jpg"
        alt="main" width="250px" height="250px"
      />
</Box>
<Typography py={3} align="start" variant="h6" component="h6" gutterBottom className="headingMainWhat">Keep More Of Your Money</Typography>

 <p className="pricingMainParagraph headingMainWhat headingMainWhatA">
 Keyword Intent is priced to be affordable for everyone, saving you hundreds of dollars per year on tools that you might not need and simple to use without overwhelming you.
</p> 


  </Grid>


</Grid>
</Box>
    </section>




<section className="mainBannerMobile">
  <Box pt={15}  display="flex" flexDirection="column" justifyContent="center" alignItems="center">
<img
        src="https://anthonyhayes.me/wp-content/uploads/keyword-intent-logo-horizontal-1.png"
        alt="main" width="88%" height="300px"  
      />
      </Box>
</section>

</Container>
<section id="mainTestimonial"></section>
    <section id="testimonial" className="" >
      <Container>

 

    <Box>
    <Typography pt={3} pb={2} variant="subtitle1" gutterBottom className="headingMainWhatA">TESTIMONIALS</Typography>
    <Typography pb={5} variant="h4" gutterBottom className="headingMainWhatAMobile">What People Are Saying About Keyword Intent</Typography>
    </Box>
    <Box className="feedbackShadow">
    <Box style={{backgroundColor: "white"}}>
    <Grid container spacing={3}>
  <Grid item xs={12} md={3}>
    
      <Box pt={1} pl={3} pb={1} className="funFactImageFlex kaushalFlex">
      <img
        src="https://anthonyhayes.me/wp-content/uploads/kaushal-photo-1.jpg"
        alt="main" width="80%" height="250px" className="stressImageAlways "
      />
 
      </Box>
  </Grid>
  <Grid item xs={12} md={7}>
  <Typography pt={1} pb={3} variant="h4" gutterBottom className="stressImageAlwaysPadding pFive">Kaushal Mistry</Typography>
  <Typography pt={3} pb={1} variant="subtitle1" gutterBottom className="stressImageAlwaysPadding pFive">Keyword Intent gives very valuable long tail keywords for research and content creation for myself and my students - Highly recommended.</Typography>
    
  </Grid>
  
</Grid>
    </Box>


    <Box>
<Grid container spacing={3}>
  <Grid item xs={12} md={3}>
    
      <Box pt={1} pl={3} pb={1} className="paddingTop">
        <p className="starsParagraph headingMainWhatA">5 of 5 stars</p>
      <img
        src="https://i.site.pictures/qhWdu.png"
        alt="main" width="85px" height="23px"
      />
 
      </Box>
  </Grid>
  <Grid item xs={12} md={7}>
  <Typography pt={3} pb={1} variant="subtitle1" gutterBottom className="headingMainWhatA headingMainWhatAMobile pFive">Customer</Typography>
    
  </Grid>
  
</Grid>
    </Box>
    </Box>

      </Container>
    </section>


    
    <section id="testimonial" >
    <Container>
      <Box mt={2} className="feedbackShadow">
 

 
   
    <Box style={{backgroundColor: "white"}}>
    <Grid container spacing={3}>
  <Grid item xs={12} md={3}>
    
      <Box pt={1} pl={3} pb={1} className="funFactImageFlex kaushalFlex">
      <img
        src="https://anthonyhayes.me/wp-content/uploads/factsfile-youtube-channel-1.jpg"
        alt="main" width="70%" height="170px" className="funFactImage stressImageAlways"
      />
 
      </Box>
  </Grid>
  <Grid item xs={12} md={7}>
  <Typography pt={1} pb={1} variant="h4" gutterBottom className="headingMainWhatAMobileb pFive">The Facts File YouTube Channel</Typography>
  <Typography pt={3} pb={1} variant="subtitle1" gutterBottom className="factFile headingMainWhatp pFive">“Mind blowing. Thank you so much for sharing such a valuable tool with us. I am not able to afford Ahref and SemRush. Now I will use this keyword research tool. Once again thanks"</Typography>
    
  </Grid>
  
</Grid>
    </Box>


    <Box>
<Grid container spacing={3}>
  <Grid item xs={12} md={3}>
    
      <Box pt={1} pl={3} pb={1}>
        <p className="starsParagraph headingMainWhatA">4.5 of 5 stars</p>
      <img
        src="https://i.site.pictures/qhtFB.png"
        alt="main" width="85px" height="26px"
      />
 
      </Box>
  </Grid>
  <Grid item xs={12} md={7}>
  <Typography pt={3} pb={1} variant="subtitle1" gutterBottom className="headingMainWhatA headingMainWhatc headingMainWhatp pFive">Another YouTube Creator from the Facts File Channel</Typography>
    
  </Grid>
  
</Grid>
    </Box>

    </Box>
      </Container>
  
    </section>
  

    <section id="testimonial" >
    <Container>
      <Box mt={2} className="feedbackShadow">


 
   
    <Box style={{backgroundColor: "white"}}>
    <Grid container spacing={3}>
  <Grid item xs={12} md={3}>
    
      <Box pt={1} pl={3} pb={1} className="funFactImageFlex kaushalFlex">
      <img
        src="https://anthonyhayes.me/wp-content/uploads/RipoN-1.jpg"
        alt="main" width="70%" height="170px" className="funFactImage stressImageAlways"
      />
 
      </Box>
  </Grid>
  <Grid item xs={12} md={7}>
  <Typography pt={1} pb={1} variant="h4" gutterBottom className="headingMainWhatAMobileb pFive">RipoN</Typography>
  <Typography pt={3} pb={1} variant="subtitle1" gutterBottom className="factFile headingMainWhatp pFive">“The Best Keyword Research Tool of 2022 | Better Than Ahrefs & Ubersuggest!...."</Typography>
    
  </Grid>
  
</Grid>
    </Box>


    <Box>
<Grid container spacing={3}>
  <Grid item xs={12} md={3}>
    
      <Box pt={1} pl={3} pb={1}>
        <p className="starsParagraph headingMainWhatA paddingTop">4.5 of 5 stars</p>
      <img
        src="https://i.site.pictures/qhtFB.png"
        alt="main" width="85px" height="26px"
      />
 
      </Box>
  </Grid>
  <Grid item xs={12} md={7}>
  <Typography pt={3} pb={1} variant="subtitle1" gutterBottom className="headingMainWhatA headingMainWhatc headingMainWhatp pFive">“This review came from RipoN who created a YouTube video review of keyword Intent and posted on his channel which has 243k subscribers, so far the <a href="https://www.youtube.com/watch?v=x0ywjoIzGeo" className="buyButtona"  target="_blank" rel="noopener noreferrer nofollow">review video has had 37,837 views</a></Typography>
    
  </Grid>
  
</Grid>
    </Box>

    </Box>
      </Container>

    </section>
  


    <section id="whatWillYouGet">
      <Container>
    <Typography pt={3} pb={8} variant="h4" gutterBottom className="mainHeadingGet">What will you get with Keyword Intent?</Typography>
   
   
    <section id="whatWeDoItLatest">
      <Box pt={5}>
    <Grid container spacing={12}>
  <Grid item xs={12} md={6} lg={4}>
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
  <img
        src="https://anthonyhayes.me/wp-content/uploads/34394288_help-1.jpg"
        alt="main" width="250px" height="250px"
      />
</Box>
<Typography py={4} variant="h6" component="h6" gutterBottom className="headingMainWhat" align="center">Keyword Research</Typography>

 <p className="pricingMainParagraph headingMainWhat headingMainWhatA">
 There are multiple keyword tools within Keyword intent, including the main  tool which can filter by wildcard, how to, comparison, best, questions, most, alternatives and ideas filters.<br /><br />
This allows you to use the filter that best suits the results you see on the first page of google for a main keyword.<br /><br />
There is also a KGR (keyword Golden Ratio) tool that will help you identify keywords that you can rank for easily, even without backlinks.
 </p> 

  
  </Grid>



  <Grid item xs={12} md={6} lg={4}>
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
  <img
        src="https://anthonyhayes.me/wp-content/uploads/34063792_online-1.jpg"
        alt="main" width="250px" height="250px"
      />
</Box>
<Typography py={3} variant="h6" component="h6" align="center" gutterBottom className="headingMainWhat">New Topics and Angles</Typography>

 <p className="pricingMainParagraph headingMainWhat headingMainWhatA">
 One thing you'll notice quickly is that when you run a search with Keyword Intent the results provided are relevant, but don't always have the keyword name that you searched. <br /><br />
The algorithm behind the tool understands the semantic nature of the results that it provides, and this is a great way to know exactly what topics, sub topics and keywords you should be using in your content, blog posts, videos and even in your ad campaigns. </p> 


  </Grid>


  <Grid item xs={12} md={6} lg={4}>
    
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
  <img
        src="https://anthonyhayes.me/wp-content/uploads/33988130_graph-1.jpg"
        alt="main" width="250px" height="250px"
      />
</Box>
<Typography py={3} align="start" variant="h6" align="center" component="h6" gutterBottom className="headingMainWhat">'Real Time' Questions </Typography>

 <p className="pricingMainParagraph headingMainWhat headingMainWhatA ">
 Understanding what questions your target audience is asking is a massive advantage to planning content that will not only rank, but will drive traffic. <br /><br />
You can run the 'Reddit Topic Hunter' tool to find questions being asked in your niche today within some high traffic sub-reddits <br /><br />
There's also an in depth 'People also ask' tool which provides up to 200 questions that Google actually ranks  and is therefore relevant to your niche.
</p> 


  </Grid>


</Grid>
</Box>
    </section>
   
    </Container>
    </section>


    <section>
        <Box className="backGroundBlack" my={8} py={10}>
    <Container>
    <Typography px={2} align="start" variant="h4" align="center" component="h6" gutterBottom className="fontMainHeading">This is Much More Than an SEO or PPC Keyword Research Tool, It's a <span className="orangeColor">Multi-Strategy Search Marketing</span>  Software Suite Designed To Help You 'Move The Needle' and Get Targeted Traffic.
</Typography>

    </Container>
        </Box>
    </section>


<section className="marginNeg">
  <Container>
  <Box>
  <Grid container spacing={2}>
  <Grid item xs={12} md={6}>
    <Box className="mainBannerMobile dDisplay">
    <img
        src="https://anthonyhayes.me/wp-content/uploads/36929648_a-male-business-icon-holding-a-glass-1.jpg"
        alt="main"  height="800px"
      />
    </Box>
  </Grid>
  <Grid item xs={12} md={6}>
  <Typography mb={4} variant="h4" component="h6" gutterBottom className="fontMainHeading"><b>The products included with Keyword Intent are... </b>
  </Typography>
      <Box>
      <div className="faq__container">
      
  
        <div className="faq__content">
          <input type="checkbox" className='itemCheckbox' id="item1" />
          <label className="faq__content-question" for="item1">THE MAIN KEYWORD INTENT TOOL
            <img className="arrow" src="https://raw.githubusercontent.com/MishaHernandez/faq-accordion-card/e78a6ca421fbe20bd9c6ff96adeb8555e8ea950f/images/icon-arrow-down.svg" alt="" /></label>
          <p className="faq__content-answer">
          The main keyword tool is unique in the results it provides compared to other tools. You'll quickly discover that your content can almost write itself with the results provided. Full training is available too on how to get the most out of this tool.
          </p>
        </div>

        <div className="faq__content">
          <input type="checkbox" className='itemCheckbox' id="item2" />
          <label className="faq__content-question" for="item2">PEOPLE ALSO ASK
            <img className="arrow" src="https://raw.githubusercontent.com/MishaHernandez/faq-accordion-card/e78a6ca421fbe20bd9c6ff96adeb8555e8ea950f/images/icon-arrow-down.svg" alt="" /></label>
          <p className="faq__content-answer">
          The questions that Google adds to the first page results can be really insightful for the search intent of the user and give you instant ideas for sub topics for articles or even for categories within your website. Its also great for creating an informed outline for articles,  videos, podcasts, and ad campaigns.
        </p>
        </div>

        <div className="faq__content">
          <input type="checkbox" className='itemCheckbox' id="item3" />
          <label className="faq__content-question" for="item3">KGR - KEYWORD GOLDEN RATIO
            <img className="arrow" src="https://raw.githubusercontent.com/MishaHernandez/faq-accordion-card/e78a6ca421fbe20bd9c6ff96adeb8555e8ea950f/images/icon-arrow-down.svg" alt="" /></label>
          <p className="faq__content-answer">
          The Keyword Golden ratio strategy is a calculation based research that divides the number of allintitle competition by the search volume provided by keyword tools and choosing keywords that have 250 searches or less and the result of that calculation (KGR Keyword Golden Ratio) comes under 0.25 then it should rank without backlinks within days. The software finds the data needed to do the calculation and highlights those keywords to add to your content.
          </p>
        </div>
      
        <div className="faq__content">
          <input type="checkbox" className='itemCheckbox' id="item4" />
          <label className="faq__content-question" for="item4">GOOGLE SERPS 'RELATED' KEYWORDS
            <img className="arrow" src="https://raw.githubusercontent.com/MishaHernandez/faq-accordion-card/e78a6ca421fbe20bd9c6ff96adeb8555e8ea950f/images/icon-arrow-down.svg" alt="" /></label>
          <p className="faq__content-answer">
          When you do a search in Google you'll see 8 'Related Searches' at the bottom of the search results page. This tool pulls that info in so that you can get ideas for sub topics for your content outline. It's essentially Google telling you how to optimize your content with terms that are relevant.

</p>
        </div>

        <div className="faq__content">
          <input type="checkbox" className='itemCheckbox' id="item5" />
          <label className="faq__content-question" for="item5">YOUTUBE AUTO SUGGEST
            <img className="arrow" src="https://raw.githubusercontent.com/MishaHernandez/faq-accordion-card/e78a6ca421fbe20bd9c6ff96adeb8555e8ea950f/images/icon-arrow-down.svg" alt="" /></label>
          <p className="faq__content-answer">
          This is a good little tool for getting seed keyword ideas to put into the main keyword intent tool. These 'auto suggest' keywords are what people are searching for in YouTube and that have traffic. It;'s a great place to start by adding a main keyword and watch as the too, updates as you type.

</p>
        </div>

        <div className="faq__content">
          <input type="checkbox" className='itemCheckbox' id="item6" />
          <label className="faq__content-question" for="item6">GOOGLE & BING TOP TEN SERPS RESULTS
            <img className="arrow" src="https://raw.githubusercontent.com/MishaHernandez/faq-accordion-card/e78a6ca421fbe20bd9c6ff96adeb8555e8ea950f/images/icon-arrow-down.svg" alt="" /></label>
          <p className="faq__content-answer">
          When you have identifies keyword phrases you want to target, it's best practice to look at the first page search results to see what content Google is already ranking and look at the format of that content (video, articles, news stories, how to posts, etc.) and this will help you create your content outline, titles, angles and style of content needed to rank. <br /><br />
The SERPS tools inside keyword Intent allow you to do that without having to leave the app </p>
        </div>

        <div className="faq__content">
          <input type="checkbox" className='itemCheckbox' id="item7" />
          <label className="faq__content-question" for="item7">REDDIT TOPIC HUNTER 
            <img className="arrow" src="https://raw.githubusercontent.com/MishaHernandez/faq-accordion-card/e78a6ca421fbe20bd9c6ff96adeb8555e8ea950f/images/icon-arrow-down.svg" alt="" /></label>
          <p className="faq__content-answer">
          Reddit is a great place to find out what your target audience is asking about right now. The Reddit Topic Hunter tool brings back real time results and you'll know within seconds what real people are asking about on the forum, rather than just keywords that keyword tools provide. This is also a great way to map out your new websites, existing content for updates and great for video content topics too. </p>
        </div>

        <div className="faq__content">
          <input type="checkbox" className='itemCheckbox' id="item8" />
          <label className="faq__content-question" for="item8">INDEXING TOOLS 
            <img className="arrow" src="https://raw.githubusercontent.com/MishaHernandez/faq-accordion-card/e78a6ca421fbe20bd9c6ff96adeb8555e8ea950f/images/icon-arrow-down.svg" alt="" /></label>
          <p className="faq__content-answer">
          The indexing tools are designed to help you find any pages that are not indexed on your site and provide the URLs for those. There is also an indexing service to help get your content indexed faster. Both of these tasks are done via 3rd party services and are conveniently available to use within keyword Intent via api keys.

 </p>
        </div>

      </div>
      </Box>


  </Grid>
</Grid>
  </Box>
  </Container>
</section>


        <section>
          <Container>
          <Box my={6}>
          <img src="https://anthonyhayes.me/wp-content/uploads/keyword-intent-flowchart-2wp-scaled-1.webp" alt="banner" width="100%" height="100%" />
          </Box>
          </Container>
        </section>


        <section>
          <Container>
          <Box my={6} className="marginFixed">
          <Typography mb={4} align="center" variant="h4" component="h6" gutterBottom className=""><b>FAQ</b>
  </Typography>
          </Box>

          <Box>
          <Grid container spacing={2}>
  <Grid item xs={12} lg={6}>
  <div className="faq__container">
      
  
      <div className="faq__content">
        <input type="checkbox" className='itemCheckbox' id="item9" />
        <label className="faq__content-question "  for="item9">How can better topical and keyword optimization impact traffic to my content? 
          <img className="arrow" src="https://raw.githubusercontent.com/MishaHernandez/faq-accordion-card/e78a6ca421fbe20bd9c6ff96adeb8555e8ea950f/images/icon-arrow-down.svg" alt="" /></label>
        <p className="faq__content-answer faq__content-answer-advance" >
        Topic optimization makes your content search engine and user friendly.

The impact can happen in both ways, and as well as rankings helping you get more traffic for more keywords it can also help you build an audience with better and more informative content that brings people back to your next pieces of content too.<br/><br/>

This is relevant whether you are creating content with articles, blog posts, videos and can even be used effectively for ad content like video ads or finding multiple angles to use in your ad groups. <br/><br/>

Keyword Intent gives you a head start in discovering angles and sub topics at speed to save you time and quickly see opportunities that you might have missed otherwise.</p>
      </div>

      <div className="faq__content">
        <input type="checkbox" className='itemCheckbox' id="item10" />
        <label className="faq__content-question" for="item10">What if I'm not good at creating content ?
          <img className="arrow" src="https://raw.githubusercontent.com/MishaHernandez/faq-accordion-card/e78a6ca421fbe20bd9c6ff96adeb8555e8ea950f/images/icon-arrow-down.svg" alt="" /></label>
        <p className="faq__content-answer faq__content-answer-advance">
        The results this keyword tool provides gives you a lot of help in creating good content. And don't worry about it too much. The only way to get better at creating content is to write more content. So, get writing, or hire a writer and give them access to your keyword Intent research.

</p>
      </div>

      <div className="faq__content">
        <input type="checkbox" className='itemCheckbox' id="item11" />
        <label className="faq__content-question" for="item11">Do i need to purchase anything to use keyword Intent?
          <img className="arrow" src="https://raw.githubusercontent.com/MishaHernandez/faq-accordion-card/e78a6ca421fbe20bd9c6ff96adeb8555e8ea950f/images/icon-arrow-down.svg" alt="" /></label>
        <p className="faq__content-answer faq__content-answer-advance">
        You can optionally buy credits for the 3rd party indexer we have integrated for website pages and bulk pages indexing (good to index your backlinks, but that's totally optional. You'll also need 2captcha to use the KGR tool.

</p>
      </div>

      </div>
  </Grid>
  <Grid item xs={12} lg={6}>

  <div className="faq__container">
      
  
      <div className="faq__content">
        <input type="checkbox" className='itemCheckbox' id="item12" />
        <label className="faq__content-question " for="item12">How accurate are the search volumes?
          <img className="arrow" src="https://raw.githubusercontent.com/MishaHernandez/faq-accordion-card/e78a6ca421fbe20bd9c6ff96adeb8555e8ea950f/images/icon-arrow-down.svg" alt="" /></label>
        <p className="faq__content-answer faq__content-answer-advance">
      
We get the search volume data direct from Google API, but the more keywords you rank the more traffic you will get. <br /><br />

Keyword Intent is designed to help you focus more on 'Topical Optimization' which is how to optimize your content for SEO today. <br /><br />
Focus on a main keyword, but also for topics and sub topics, with multiple keywords being targeted within each sub-topic and making sure that you met the search intent for each section sub heading for best results..
Keyword Intent makes this easy and intuitive with the data provided.</p>
      </div>

      <div className="faq__content">
        <input type="checkbox" className='itemCheckbox' id="item13" />
        <label className="faq__content-question" for="item13">What training is provided and when?
          <img className="arrow" src="https://raw.githubusercontent.com/MishaHernandez/faq-accordion-card/e78a6ca421fbe20bd9c6ff96adeb8555e8ea950f/images/icon-arrow-down.svg" alt="" /></label>
        <p className="faq__content-answer faq__content-answer-advance">
       
The training is done in short video format showing how to use the tools with an example campaign done in the form of a live demo, moving step-by-step and using a logical flow from content mapping for a new topic gathering ideas and angles to use for multiple pieces of content, though to mapping out an article with main keyword, sub headings, featured snippets questions and FAQ schema optimization. <br /> <br />

This is designed to give you an easy to understand grasp of creating content for SEO, video marketing and even advertising strategies that work today. <br /> <br />
This will also be followed up with broader weekly webinar training that will cover multiple digital marketing topics including Content optimization, Google and YouTube ads, list building and lead generation,  audience building and more.  <br /> <br />

However, we do a Q&A session at the end of every live training session, and you can ask questions about Keyword research, Keyword Intent, topical optimisation, or any other online marketing topic that you want to ask about
</p>
      </div>

      <div className="faq__content">
        <input type="checkbox" className='itemCheckbox' id="item14" />
        <label className="faq__content-question" for="item14">How much does Keyword Intent cost? What factors go into content optimization costs?
          <img className="arrow" src="https://raw.githubusercontent.com/MishaHernandez/faq-accordion-card/e78a6ca421fbe20bd9c6ff96adeb8555e8ea950f/images/icon-arrow-down.svg" alt="" /></label>
        <p className="faq__content-answer faq__content-answer-advance">
       
Keyword Intent is priced to be affordable for everyone, it does not cost hundreds of dollars per year and is much lower cost than other keyword research tools. <br /> <br />

Content optimization costs depend on whether you are creating your content or outsourcing it.<br /><br />
Keyword Intent can lower your costs by helping you create a proper outline of your content with keywords to use within each section, ready to hand over to writers and keep the costs down for research time, while helping you get a better ROI from the traffic you generate from your content.
</p>
      </div>

      </div>

  </Grid>
</Grid>
          </Box>

          </Container>
        </section>

        <section>
        <Box className="backGroundBlack backGroundBlackMargin" mt={8} py={10}>
    <Container>
    <Typography px={2} align="start" variant="h4" align="center" component="h4" gutterBottom className="headingFoos">If you're looking for an easy to operate tool <span className="orangeColor">That Focuses On Topics, Angles and Keywords </span>to help you optimize and create better content <span className="orangeColor"> without spending endless hours of research</span>, that won't cost you hundreds (or thousands) of dollars per year, then this is for you...
</Typography>

    </Container>
        </Box>
    </section>


    <section>
      <Container>
      <Box className="grayBackgroundColor" p={1}>

      <Typography variant="h4"  component="h4" gutterBottom className="headingFoo">So who is this for exactly?</Typography>
      <Typography variant="body1" >Anybody who falls into one of these categories:</Typography>
      <ul className="grayBackgroundColorPadding">
        <li className="grayBackgroundColor grayBackgroundColora">You struggle to come up with topics and angles to use for your content marketing or ad campaigns.</li>
        <li className="grayBackgroundColor grayBackgroundColora">You don't understand the ins-and-outs of complicated and hard to learn keyword research tools.</li>
        <li className="grayBackgroundColor grayBackgroundColora">You don't want to spend hundreds of dollars per month on tools that need a huge learning curve just to operate at a level that's useful to you to get meaningful results.
</li>
        <li className="grayBackgroundColor grayBackgroundColora">You already started - but struggle to come up with angles to create original content or ad campaign ideas to grow your audience.
</li>
        <li className="grayBackgroundColor grayBackgroundColora">You just want quick and easy research handed on a plate that you can easily identify content angles, and sub-topic ideas quickly and dig deeper into for fast content mapping.
</li>
        <li className="grayBackgroundColor grayBackgroundColora">You want a tool that you can give access to your writing assistants to use as a quick guide to expand the search intent of your articles to help you rank faster and get traffic.
</li>
        <li className="grayBackgroundColor grayBackgroundColora">You don't want an expensive monthly commitment for tools you know you will never get the most use from because you don't have time.
</li>
        <li className="grayBackgroundColor grayBackgroundColora">You want unlimited access to the tools you pay for without credit limits that you barely understand.
</li>
        <li className="grayBackgroundColor grayBackgroundColora">You just want fast, easy research to find multiple keywords to create content around with search intent that digs deep without having to spend between hundreds of thousands of dollars per year just for their basic packages.
</li>
        <li className="grayBackgroundColor grayBackgroundColora">You want to be a founder member of a product that keeps improving with new, unique and exciting features on a regular basis.
</li>
        <li className="grayBackgroundColor grayBackgroundColora">You want to be part of a community that can share strategy, ideas and even suggest new features and feel like you're being listened to.
</li>
        <li className="grayBackgroundColor grayBackgroundColora">You want to take advantage of early pricing and lock it it forever while we build the membership up and not have to pay more later.
</li>
      </ul>
      <Typography variant="body1" py={2} >Did of of those hit a pain point? </Typography>
      <Typography variant="body1" pb={1}>If it did you're not alone, and i want to solve those problems for you today.

</Typography>
      </Box>
      </Container>
    </section>


    <section>
      <Container>
        <Box>
        <Typography variant="h5" align="center" mt={3} mb={5} className="boldest">The Main Keyword Intent Research Tool</Typography>
        <img src="https://anthonyhayes.me/wp-content/uploads/keyword-intent-screenshot-1-1536x792.png" alt="Main Tool" width="100%" />
        </Box>
        <Box className="emptyBorder" mt={3} mb={3}>

        </Box>
      </Container>
    </section>


    <section>
      <Container>
        <Box>
        <Typography variant="h5" align="center" mt={4} mb={3} className="boldest">The Main Keyword Intent Research Tool</Typography>
        <Typography variant="body1" align="center" mt={3} mb={3} className="fontest" >Finds Opportunities For Topics and Angles</Typography>
        <img src="https://anthonyhayes.me/wp-content/uploads/keywordintent-screenshot-2-1536x792.png" alt="Main Tool" width="100%" />
        </Box>
        <Box className="emptyBorder" mt={3} mb={3}>

        </Box>
      </Container>
    </section>


    <section>
      <Container>
        <Box>
        <Typography variant="h5" align="center" mt={4} mb={3} className="boldest">The Main Keyword Intent Research Tool</Typography>
        <Typography variant="body1" align="center" mt={3} mb={5} className="fontest fontestq" >We Tested It For Launch Jacking With Exact Product Name From a Launch last Week

</Typography>
        <img src="https://anthonyhayes.me/wp-content/uploads/launch-jacking-keyword-intent-1536x392.png" alt="Main Tool" width="100%" />
        </Box>

        <Box>
        <iframe width="100%" className="youtubeVideo" height="550" src="https://www.youtube.com/embed/PWQcs_DBBJI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </Box>

        <Box className="emptyBorder" mt={3} mb={3}>

        </Box>
      </Container>
    </section>


    <section>
      <Container>
        <Box>
        <Typography variant="h5" align="center" mt={4} mb={3} className="boldest">The Main Keyword Intent KGR (Keyword Golden Ratio) Tool </Typography>
        <Typography variant="body1" align="center" mt={3} mb={3} className="fontest" >Super Easy Keyword With Traffic...

</Typography>
        <img src="https://anthonyhayes.me/wp-content/uploads/KGR-Tool-Keyword-Intent-1536x432.png" alt="Main Tool" width="100%" />
        </Box>
        <Box className="emptyBorder" mt={3} mb={3}>

        </Box>
      </Container>
    </section>

    <section>
      <Container>
        <Box>
        <Typography variant="h5" align="center" mt={4} mb={3} className="boldest">The Main Keyword Intent People Also Ask Tool 
</Typography>
        <Typography variant="body1" align="center" mt={3} mb={3} className="fontest" >So We Can Put The Product Type Into People Also Ask Tool To Find What People Are Asking...
</Typography>
        <Typography variant="body1" align="center" mt={3} mb={3} className="fontest" >This Could Make Great Blog or Video Content


</Typography>
        <img src="https://anthonyhayes.me/wp-content/uploads/people-also-ask-tool-keyword-intent-1536x545.png" alt="Main Tool" width="100%" />
        </Box>
      
      </Container>
    </section>


    <section id="prices">
      <Box className="bluest" mt={2} mb={4} pb={5}>
        <Container>
        <Box my={4} className="pricesPaddingFixed">
        <Typography variant="h4" align="center" py={4} className="weighest" >
        Grab Keyword Intent Today From Just $134
 </Typography>
        </Box>

      <Box mt={2} pb={5} className="pricesPaddingFixeda">
      <Grid container spacing={2}>
  <Grid item xs={12} lg={6}>
  <Typography variant="body1" pt={4} px={1} className="gulliest" >
  Here we have a highly attractive offer for you and you need to make a decision right now if you want to take advantage of the founder members offer. Keyword Intent will not be at this price for very long, jump in now and lock in your special discount.
 </Typography>
  </Grid>
  <Grid item xs={12} lg={6} >
    <Box className='kaushalFlex'>
    <img src="https://i.site.pictures/qGLWX.png" alt="buy button" className="imageFixed" />
    </Box></Grid>
</Grid>
      </Box>

      <Box className="parentBox" px={4} py={8} >
          <Box>
          <Grid container spacing={2}>
  <Grid item xs={12} md={6} lg={4}>
    <Box display="flex" >
    <img src="https://anthonyhayes.me/wp-content/uploads/copy-2-guarantee.png" />
     <Box sx={{ flexGrow: 1}} > <Typography variant="body1" pt={4} px={1} className="gulliest" ><b>100%</b> Satisfaction Guaranteed</Typography></Box>
    </Box>
  </Grid>
  <Grid item xs={12} md={6} lg={4}>
  <Box display="flex" >
    <img src="https://anthonyhayes.me/wp-content/uploads/copy-2-secure.png" />
     <Box sx={{ flexGrow: 1}} > <Typography variant="body1" pt={4} px={1} className="gulliest" > Secure Payment

</Typography></Box>
    </Box>
  </Grid>
  <Grid item xs={12} md={6} lg={4}>
  <Box display="flex" >
    <img src="https://i.site.pictures/qKInK.png" />
     
</Box>
  
  </Grid>
</Grid>
          </Box>
      
          <a href="https://adnan.zaxaa.com/o/46738399960783/1"> <Button variant="contained" className="buyItNow" >BUY IT NOW</Button> </a>
      
      </Box>

      </Container>
      </Box>
    </section>





    <section>
      <Container>

        <Box my={2} className="borderast" display="flex" flexDirection="column" justifyContent="center" alignItems="center">

        <Box my={4}>
          <img src="https://i.site.pictures/qG618.png" alt='quote' />
        </Box>
        <Box my={1}>
          <img src="https://anthonyhayes.me/wp-content/uploads/sterling-hall-favorite-2-1.jpg" alt='sterling-hall-favorite' />
        </Box>

        <Typography variant="body1"  mt={3} mb={3} className="fontest lineest paddingest" px={14} >
        I like and use keywordintent.io, <br />

I started with the free version which is easy to use, and has search volumes. When the paid version came along, I upgraded to premium. It was a no brainer for me. <br />

It quickly gets ideas for topically relevant keywords. <br />

It also has new powerful features found in much higher subscription tools but not in a single subscription tool. I also own some of those, so I can see the difference and I use this tool first.
        </Typography>

        <Typography variant="body1" align="center" mt={1} mb={3} className="fogest" >STERLING HALL
</Typography>

        </Box>

      </Container>      
    </section>

    <section>
      <Container>

        <Box my={3} className="borderast" display="flex" flexDirection="column" justifyContent="center" alignItems="center">

        <Box my={4}>
          <img src="https://i.site.pictures/qG618.png" alt='quote' />
        </Box>
        <Box my={1}>
          <img src="https://anthonyhayes.me/wp-content/uploads/Andrew-Samootin-297x300.jpeg" width="200px" height="200px" alt='sterling-hall-favorite' />
        </Box>

        <Typography variant="body1"  mt={3} mb={3} className="fontest lineest paddingest" px={14} >
          
        Keyword Intent is a game changer! It has helped find ways to increase Topic Optimizations in new ways and show even more buyer intent searches quickly.


           </Typography>

        <Typography variant="body1" align="center" mt={1} mb={3} className="fogest" >ANDREW SAMOOTIN
</Typography>

        </Box>

      </Container>      
    </section>

    <section>
      <Container>

        <Box my={3} className="borderast" display="flex" flexDirection="column" justifyContent="center" alignItems="center">

        <Box my={4}>
          <img src="https://i.site.pictures/qG618.png" alt='quote' />
        </Box>
        <Box my={1}>
          <img src="https://anthonyhayes.me/wp-content/uploads/Dhes-Pillay-_-Seo-Specialists-Sydney-300x204.png" width="200px" height="200px" alt='sterling-hall-favorite' />
        </Box>

        <Typography variant="body1"  mt={3} mb={3} className="fontest lineest paddingest" px={14}  >
        Only just digging into keyword intent, however the keyword research tool itself is saving me a bunch of time clustering keywords and gathering information regarding search volume, competition and trends. <br /> <br />
I can now get rid of several keyword research tools and subscriptions, as this tool is pretty much an all in one. It's a strong alternative to Ubersuggest, and I am likely going to stop needing to purchase credits from Keywords Everywhere going forward. The people also ask tool is also a massive timesaver, and it means I don't need to go crawling through Google to find relevant Q&A details to add to my content.
            
<br /> <br />This has very quickly become my favourite keyword research tool


            
            </Typography>

        <Typography variant="body1" align="center" mt={1} mb={3} className="fogest" >DHES PILLAY

</Typography>

        </Box>

      </Container>      
    </section>


    <section>
      <Container>

        <Box my={3} className="borderast" display="flex" flexDirection="column" justifyContent="center" alignItems="center">

        <Box my={4}>
          <img src="https://i.site.pictures/qG618.png" alt='quote' />
        </Box>

        <Typography variant="body1"  mt={3} mb={3} className="fontest lineest paddingest" px={14} >
        I was able to write a blog post outline within minutes using Keyword Intent that is 100% better than what an Ai tool could provide and is completely unique to my content too. This is a great tool and so many ways to use it too with long tail Keyword Golden Ratio and the Reddit topic hunter generates some cracking ideas for new content. Brilliant job!


            
            </Typography>

        <Typography variant="body1" align="center" mt={1} mb={3} className="fogest" >PETER DAWSON

</Typography>

        </Box>

      </Container>      
    </section>

    </>
  )
}

export default Pricing