// 

import React, {useState} from 'react';
import { BallTriangle } from  'react-loader-spinner';
import {Box, Grid, TextField, Button, Container } from '@mui/material';
import DataTable from 'react-data-table-component';
import axios from "axios";
import PopulateQuora from "../Component/PopulateQuora";




const submitButton = {
    paddingTop: "14px",
    paddingBottom: "14px",
    display: "block",
    width: "100%",
    // marginTop: "15px"
}

function GoogleQuora() {


    const [loading, setLoading] = useState();
    const [bulkIndexChecker, setBulkIndexChecker] = useState([]);
    const [messageerror, setMessageError] = useState(false);
    const [dataCopyToClip, setDataCopyToClip] = useState();
    const [selectedQuestionData, setSelectedQuestionData] = useState();
    // const [inputValue, setInputValue] = useState();
    // const [userApiKey, setUserApiKey] = useState();
// https://keywordintent-reddit.herokuapp.com 
//  subRedditValue, secretvalue, idValue
// f97fe239d5dc18989f8cf2732b0eaf02

    const [data, setData] = useState({    
    subRedditValue: "",
    })


console.log(data.subRedditValue)

    const { subRedditValue } = data

    const inputhandleChange = e =>
      setData({ ...data, [e.target.name]: e.target.value })


// console.log(subRedditValue)
  
   

   
   
    const  handleSubmit = async (e) => {
        e.preventDefault();
       
         await axios.post("https://google-it-quora.herokuapp.com", subRedditValue , {
            headers: {'Access-Control-Allow-Origin': '*' , 'Content-Type': 'text/plain' }
          })
            .then((response) => {
            console.log(response.data);
          setBulkIndexChecker(response.data) 
            setLoading(false);
            setMessageError(false)
              
            }).catch((error) => {
            
              setMessageError(true)
              setLoading(false);
            }) 
        } 

        // console.log(bulkIndexChecker.filter(x => x.url.startsWith("https://www.quora")));

        const customStyles = {
            headCells: {
              style: {
                backgroundColor: 'rgb(255, 0, 223)',
                color: '#FFF',
                fontWeight: 'bold',
                fontSize: '17px',
              },
            },
            cells: {
              style: {
                fontSize: '14px',
              },
            },
          };



          const columns = [
            {
            name: '# ',
            cell: (row, index) => index + 1,
            width: '56px',
            // sortFunction: caseInsensitiveSort
            },
            {
                name: 'Question Title',
                selector: row => row.title,
                // width: '651px',
                // sortable: true, 'https://www.reddit.com"+${row.data.permalink}+"'
                // `cell: row => <a href="https://www.scraperapi.com/${row.data.permalink}" target="_blank" rel="noopener noreferrer nofollow">click here</a>`
             
            },
            {
                name: 'Question Url',
                selector: row => row.link,
                width: '450px', 
                
            }
          ];



          const handleChange = ({ selectedRows }) => {
            // You can set state or dispatch with something like Redux so we can use the retrieved data
           
          
            
            var arr1 = selectedRows.map(function(item){
              return [item.title, item.link ];
          });
          
          
          var arr2 = selectedRows.map(function(item){
            return item.title ;
          });
          
          setDataCopyToClip(arr2.join('\n'))
          
          setSelectedQuestionData(arr1)
          };  


          if(selectedQuestionData !== undefined && selectedQuestionData.length !== 0){

            var lengthData = selectedQuestionData.length
            }


  return (
    <Container maxWidth="xl" > 
    <Box >
   
    <Grid item md={12} mt={4}>
       
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={9} >
            <TextField required style ={{width: '100%'}} label="Enter Topic" variant="outlined"  onChange={inputhandleChange} name="subRedditValue" value={subRedditValue} />
            </Grid>
            
            
            <Grid item xs={12} md={3}>
                     <Button variant="contained"  color="primary"  type="submit" style={submitButton} onClick={() => setLoading(true)} >SUBMIT</Button>
              </Grid>
          </Grid>
        </form>
       

{/* Form Div end */}
        {messageerror && 
        <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '60vh' }} >
         <h2 className="errorHeading">Oops! Something went wrong</h2>
         <Box className="errorMessage" mt={5}>Please take a screenshot of <a href="https://screenful.com/how-to-open-the-browser-console" target="_blank" rel="noopener noreferrer" className="errorMessage erroranchor" >browser developer console</a> and send this to our live chat support agent</Box>
          
        </Grid>
        }


        {loading ? <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '80vh' }} >
        <Grid item md={7}>
        <BallTriangle color="#FF00DF" ariaLabel="ball-triangle-loading" />
        </Grid>   
        <Grid item md={5}>
        </Grid> 
        </Grid> 
      
      : null }
 

<Box mt={7}>


{  bulkIndexChecker !== undefined && bulkIndexChecker.length !== 0 ?

<PopulateQuora populateData={selectedQuestionData} selectedRowsData={lengthData} copyToClipboard={dataCopyToClip} />
  : null
}


<DataTable columns={columns} data={bulkIndexChecker} customStyles={customStyles} selectableRows onSelectedRowsChange={handleChange} />
</Box>


        </Grid>
      
        </Box> 
       </Container> 
  
  )
}

export default GoogleQuora;