import React, {useState, useEffect} from "react";
import { CirclesWithBar } from  'react-loader-spinner';
import {Box, Grid, TextField, Button, Container, TextareaAutosize, Modal} from '@mui/material';
import axios from "axios";
import ReactTooltip from 'react-tooltip';
// import PopulateKwBuilder from '../Component/PopulateKwBuilder'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #FF00DF',
  boxShadow: 24,
  p: 4,
};



const submitButton = {
    paddingTop: "14px",
    paddingBottom: "14px",
    display: "block",
    width: "100%"
}

// const array = [
//     {"data": "adnan hassan"},
//     {"data": "faizan"}
// ]


function KeywordBuilder() {
    const [rotatorState, setRotatorState] = useState(true);
    const [inputValue, setInputValue] = useState("");
    const [responseData, setResponseData] = useState([]);
    const [loading, setLoading] = useState();
    const [messageerror, setMessageError] = useState();
    const [inputValueManual, setinputValueManual] = useState("");
    const [inputValueuniversal, setinputValueuniversal] = useState("");
    const [inputValueuniversalchild1, setinputValueuniversalchild1] = useState([]);
    const [inputValueuniversalchild2, setinputValueuniversalchild2] = useState([]);
    const [inputValueuniversalchild3, setinputValueuniversalchild3] = useState([]);

//      2nd box States

    const [inputValue1, setInputValue1] = useState("");
    const [responseData1, setResponseData1] = useState([]);
    const [loading1, setLoading1] = useState();
    const [messageerror1, setMessageError1] = useState();
    const [inputValueManual1, setinputValueManual1] = useState("");
    // const [populateDataAll, setPopulateDataAll] = useState([{"a":"Adnan"}, {"a":"Hassan"}]);
const [inputUniversalValue, setinputUniversalValue] = useState("")
     const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

// 3rd Box States

    const [inputValue2, setInputValue2] = useState("");
    const [responseData2, setResponseData2] = useState([]);
    const [loading2, setLoading2] = useState();
    const [messageerror2, setMessageError2] = useState();
    const [inputValueManual2, setinputValueManual2] = useState("");



    const  handleSubmit = async (e) => {
        e.preventDefault();
        // https://upatedtofay.herokuapp.com/inputData
    
        
        
        
         await axios.post("https://kwintent5.herokuapp.com/inputData", {inputValue} , {
        
            headers: { 'Access-Control-Allow-Origin': '*' , 'Content-Type': 'application/json'}
          })
            .then((response) => {
                setResponseData(response.data);
              setLoading(false);
              setRotatorState(false)
              setMessageError(null)
            
            }).catch(error => {
            
            
              setMessageError(error)
              setLoading(false);
            })
        } 



        
        useEffect(() => {
          var arr2 = responseData.map(function(item){
            return item.text
          });
          var arr3 = arr2.join('\n') 
          setinputValueManual((prev) => prev + arr3)
          setRotatorState(true)
        }, [inputValue, responseData])

      

        

          //  2nd Box Submit function

          const  handleSubmit1 = async (e) => {
            e.preventDefault();
            // https://upatedtofay.herokuapp.com/inputData
        
            
            
            
             await axios.post("https://kwintent7.herokuapp.com/inputData", inputValue1 , {
            
                headers: { 'Access-Control-Allow-Origin': '*' , 'Content-Type': 'text/plain'}
              })
                .then((response) => {
                    setResponseData1(response.data);
                  setLoading1(false);
                  setRotatorState(false)
                  setMessageError1(null)
                
                }).catch(error => {
                
                
                  setMessageError1(error)
                  setLoading1(false);
                })
            } 
    

            useEffect(() => {
    
            var arr4 = responseData1.map(function(item){
                return item
              });
            var arr5 = arr4.join('\n') 
            setinputValueManual1((prev) => prev + arr5)
            setRotatorState(true)
          }, [inputValue1, responseData1])





            //  3rd Submit function

              
          const  handleSubmit2 = async (e) => {
            e.preventDefault();
            
             await axios.post("https://kwintent6.herokuapp.com/inputData", {inputValue2} , {
            
                headers: { 'Access-Control-Allow-Origin': '*' , 'Content-Type': 'application/json'}
              })
                .then((response) => {
                    setResponseData2(response.data);
                  setLoading2(false);
                  setRotatorState(true)
                  setMessageError2(null)
                
                }).catch(error => {
                
                
                  setMessageError2(error)
                  setLoading2(false);
                })
            } 
    
           

            useEffect(() => {
  var arr6 = responseData2.map(function(item){
    return  item.text
  });


var arr7 = arr6.join('\n') 
  setinputValueManual2((prevv) => prevv + arr7)
  setRotatorState(false)
  // console.log(inputValueManual2)  
}, [inputValue2, responseData2])
   

useEffect(() => {
    
    setinputValueuniversalchild1(inputValueManual.split('\n'));
      setinputValueuniversalchild2(inputValueManual1.split('\n'));
      setinputValueuniversalchild3(inputValueManual2.split('\n'));

      
        let c =  inputValueuniversalchild1.map(function(value, index) {
          return `${value} ${inputValueuniversalchild2[index]} ${inputValueuniversalchild3[index]}` ;
        });
        setinputValueuniversal(c.map(e => e.replaceAll("undefined", "").trim()).join('\n')) 
      
  }, [ inputValueManual, inputValueManual1, inputValueManual2, rotatorState ])
  

//   const incrementViews = useCallback(() => {
   
//     setinputValueuniversalchild1(inputValueManual.split('\n'));
//     setinputValueuniversalchild2(inputValueManual1.split('\n'));
//     setinputValueuniversalchild3(inputValueManual2.split('\n'));

   
//       let c =  inputValueuniversalchild1.map(function(value, index) {
//         return `${value} ${inputValueuniversalchild2[index]} ${inputValueuniversalchild3[index]}` ;
//       });


//       setinputValueuniversal(c.map(e => e.replaceAll("undefined", "").trim())) 



// }, [inputValueManual, inputValueManual1, inputValueManual2, responseData, responseData1, responseData2,  inputValue, inputValue1, inputValue2]);
   
 
// let a = ["undefined this", "that undefined ", "undefined value undefined"]

// const result = a.map(e => e.replaceAll("undefined", "").trim())
// console.log(result)


// let a=arry1.map(function(value, index) {
//   return `${value} ${arry2[index]||""} ${arry3[index]||""}` ;
// });



// useEffect(() => {

// incrementViews()


// }, [incrementViews])



  return (
    <>
    
    <Container maxWidth="xl"> 

    <Grid container spacing={1}>
        <Grid item xs={12} md={4}>
       

    <Box>

    <Grid   container   mt={4} direction="column" >
         <h2 data-tip="This is New Tooltip For Modifiers">Suggest Modifiers</h2>
         <Box mt={1}>
         <h2 data-tip="This is New Tooltip">Wants</h2>
         <ReactTooltip place="right" type="dark" effect="float" />
         </Box>
         
        </Grid>



        <Grid item xs={12} mt={4}>
        
        <form autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={9} >
           <TextField required style ={{width: '100%'}} label="Enter the keyword" variant="outlined"  onChange={(e) => setInputValue(e.target.value)} value={inputValue} />
            </Grid>
            <Grid item xs={12} md={3}>
                     <Button variant="contained"  color="primary"  type="submit" style={submitButton} onClick={() => setLoading(true)} >SUBMIT</Button>
              </Grid>
          </Grid>
        </form>
        </Grid>

    {messageerror && 
        <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '60vh' }} >
         <h2 className="errorHeading">Oops! Something went wrong</h2>
         <Box className="errorMessage" mt={5}>Please take a screenshot of <a href="https://screenful.com/how-to-open-the-browser-console" target="_blank" rel="noopener noreferrer" className="errorMessage erroranchor" >browser developer console</a> and send this to our live chat support agent</Box>
          
        </Grid>
        }


        {loading ? <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '50vh' }} >
        <Grid item xs={12}>
        <CirclesWithBar color="#FF00DF" ariaLabel="falling-lines-loading" />
        </Grid>   
        
        </Grid> 
      
      : null }


        <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"  mt={3}  >
        
         {/* <TextField id="outlined-multiline-static"  className="customScrollbar" multiline minRows={16} maxRows={Infinity}  style ={{width: '100%'}}  value={arr3} />   */}
        
        {/* <FormControl  variant="outlined"  style ={{width: '100%'}} >
            <OutlinedInput multiline minRows={16} maxRows={Infinity} id="outlined-adornment-weight" value={arr3} />
        </FormControl> */}
       {/* <TextareaAutosize  style ={{width: '100%', paddingLeft: '10px', paddingTop: "5px", fontSize: "15px" }}  minRows={16} maxRows={Infinity} value={inputValueManual} onChange={onChangeHandler} className="textareaColor" /> */}
       <TextareaAutosize  style ={{width: '100%', paddingLeft: '10px', paddingTop: "5px", fontSize: "15px" }}  minRows={16} maxRows={Infinity} value={inputValueManual} onChange={(e) => setinputValueManual(e.target.value)} className="textareaColor" />
        </Grid>

    </Box>
    </Grid>
  

                {/* 2nd Box */}

                <Grid item xs={12} md={4}>
       

       <Box>
   
       <Grid   container   mt={4} direction="column" >
            <h2 data-tip="This is New Tooltip">Suggest Keywords</h2>
            <Box mt={1}>
            <h2 data-tip="This is New Tooltip">Needs</h2>
            </Box>
            <ReactTooltip place="right" type="dark" effect="float" />
           </Grid>
   
   
           <Grid item xs={12} mt={4}>
           
           <form autoComplete="off" onSubmit={handleSubmit1}>
             <Grid container spacing={1}>
               <Grid item xs={12} md={9} >
              <TextField required style ={{width: '100%'}} label="Enter the keyword" variant="outlined"  onChange={(e) => setInputValue1(e.target.value)} value={inputValue1} />
               </Grid>
               <Grid item xs={12} md={3}>
                        <Button variant="contained"  color="primary"  type="submit" style={submitButton} onClick={() => setLoading1(true)} >SUBMIT</Button>
                 </Grid>
             </Grid>
           </form>
           </Grid>
   
       {messageerror1 && 
           <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '60vh' }} >
            <h2 className="errorHeading">Oops! Something went wrong</h2>
            <Box className="errorMessage" mt={5}>Please take a screenshot of <a href="https://screenful.com/how-to-open-the-browser-console" target="_blank" rel="noopener noreferrer" className="errorMessage erroranchor" >browser developer console</a> and send this to our live chat support agent</Box>
             
           </Grid>
           }
   
   
           {loading1 ? <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '50vh' }} >
           <Grid item xs={12}>
           <CirclesWithBar color="#FF00DF" ariaLabel="falling-lines-loading" />
           </Grid>   
           
           </Grid> 
         
         : null }
   
   
           <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"  mt={3}  >
           
            {/* <TextField id="outlined-multiline-static"  className="customScrollbar" multiline minRows={16} maxRows={Infinity}  style ={{width: '100%'}}  value={arr3} />  */}
           
           {/* <FormControl  variant="outlined"  style ={{width: '100%'}} >
               <OutlinedInput multiline minRows={16} maxRows={Infinity} id="outlined-adornment-weight" value={arr3} />
           </FormControl> */}
          <TextareaAutosize  style ={{width: '100%', paddingLeft: '10px', paddingTop: "5px", fontSize: "15px" }}  minRows={16} maxRows={Infinity} value={inputValueManual1} onChange={(e) => setinputValueManual1(e.target.value)} className="textareaColor" />
          {/* <TextareaAutosize  style ={{width: '100%', paddingLeft: '10px', paddingTop: "5px", fontSize: "15px" }}  minRows={16} maxRows={Infinity} value={inputValueuniversal} className="textareaColor" /> */}
          <Button variant="contained" style= {{marginTop: "10px"}} onClick={handleOpen}>Rotator</Button>
          {/* <PopulateKwBuilder populateData={populateDataAll} /> */}
<Modal
  open={open}
  onClose={handleClose}
>
  <Box sx={style}>
     <TextareaAutosize  style ={{width: '100%', paddingLeft: '10px', paddingTop: "5px", fontSize: "15px" }}  minRows={16} maxRows={Infinity} value={inputValueuniversal}  /> 
         
  </Box>
</Modal>
          {/* <TextareaAutosize  style ={{width: '100%', paddingLeft: '10px', paddingTop: "5px", fontSize: "15px" }}  minRows={16} maxRows={Infinity} value={inputValueuniversal} className="textareaColor" /> */}
           </Grid>
   
       </Box>
       </Grid>           


            {/* 3rd Box */}


            <Grid item xs={12} md={4}>
       

       <Box>
   
       <Grid   container   mt={4} direction="column"    >

            <h2 data-tip="This is New Tooltip">Suggest Locations</h2>
            <Box mt={1}>
            <h2 data-tip="This is New Tooltip">Fears</h2>
            </Box>
            {/* <p >Tooltip</p> */}
            <ReactTooltip place="right" type="dark" effect="float" />

            
             
           </Grid>
   
   
           <Grid item xs={12} mt={4}>
           
           <form autoComplete="off" onSubmit={handleSubmit2}>
             <Grid container spacing={1}>
               <Grid item xs={12} md={9} >
                  <TextField required style ={{width: '100%'}} label="Enter the keyword" variant="outlined"  onChange={(e) => setInputValue2(e.target.value)} value={inputValue2} />
               </Grid>
               <Grid item xs={12} md={3}>
                  <Button variant="contained"  color="primary"  type="submit" style={submitButton} onClick={() => setLoading2(true)} >SUBMIT</Button>
                </Grid>
             </Grid>
           </form>
           </Grid>
   
       {messageerror2 && 
           <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '60vh' }} >
            <h2 className="errorHeading">Oops! Something went wrong</h2>
            <Box className="errorMessage" mt={5}>Please take a screenshot of <a href="https://screenful.com/how-to-open-the-browser-console" target="_blank" rel="noopener noreferrer" className="errorMessage erroranchor" >browser developer console</a> and send this to our live chat support agent</Box>
             
           </Grid>
           }
   
   
           {loading2 ? <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '50vh' }} >
           <Grid item xs={12}>
           <CirclesWithBar color="#FF00DF" ariaLabel="falling-lines-loading" />
           </Grid>   
           
           </Grid> 
         
         : null }
   
   
           <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"  mt={3}  >
           
            {/* <TextField id="outlined-multiline-static"  className="customScrollbar" multiline minRows={16} maxRows={Infinity}  style ={{width: '100%'}}  value={arr3} />  */}
           
           {/* <FormControl  variant="outlined"  style ={{width: '100%'}} >
               <OutlinedInput multiline minRows={16} maxRows={Infinity} id="outlined-adornment-weight" value={arr3} />
           </FormControl> */}
          <TextareaAutosize  style ={{width: '100%', paddingLeft: '10px', paddingTop: "5px", fontSize: "15px" }}  minRows={16} maxRows={Infinity} value={inputValueManual2} onChange={(e) => setinputValueManual2(e.target.value)} className="textareaColor" />
           </Grid>
   
       </Box>
       </Grid>    





                </Grid>   
    </Container>

    </>
  )
}

export default KeywordBuilder