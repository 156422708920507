import React, { Component } from "react";
import fetchJsonp from "fetch-jsonp";
import { Box, Button, Container } from '@mui/material';
// import Suggestions from "./Suggestions";
import "./Suggestions.css";
import DataTable from 'react-data-table-component';
import { CopyToClipboard } from "react-copy-to-clipboard";
// import * as XLSX from 'xlsx';


/*
* Google Suggest API 
* There is no actual documentation for this API
*/
// const API_URL =
//   "https://corsanywhere.herokuapp.com/http://suggestqueries.google.com/complete/search?client=chrome&q=";
const API_URL =
  "https://suggestqueries.google.com/complete/search?client=youtube&q=";

  const customStyles = { headCells: {
    style: {
      backgroundColor: 'rgb(255, 0, 223)',
      color: '#FFF',
      fontWeight: 'bold',
      fontSize: '17px',
    },
  },
  cells: {
    style: {
      fontSize: '14px',
    },
  },
};

var columns = [{
  name: 'Keywords',
  cell: (row) => row
  }];

  

class GoogleAutoSuggest extends Component {

  constructor(props){
    super(props);
    this.state = {
      query: "",
      results: [],
      copyClipboard: [],
      // selectedRowsExportXlsx: [],
    };

    // this.downloadExcel = this.downloadExcel.bind(this);
}


  getInfo = () => {
    fetchJsonp(`${API_URL} + ${this.state.query}`)
      .then(function (response) {
        return response.json();
      })
      .then(json => {
        const fetchedData = json[1];
        let suggestions = [];
        fetchedData.forEach(element => {
          suggestions.push(element[0]);
        });
        this.setState({
          results: [...suggestions]
        });
      })
      .catch((ex) => {
        console.log("parsing failed", ex);
      });
  };

  handleInputChange = () => {
    this.setState(
      {
        query: this.search.value
      },
      () => {
        /*
        * If length of search-param is > 2 then only fetch data
        */
        if (this.state.query && this.state.query.length > 1) {
          this.getInfo();
        }
      }
    );
  };



   handleChange = ({selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', selectedRows);


    var arr3 = selectedRows.map(function(item){
      return item ;
    });

    this.setState(
      {
        // selectedRowsExportXlsx: selectedRows,
        copyClipboard: arr3.join('\n')
      })
  
  };

  //  downloadExcel = (selectedRowsExportXlsx)  => {
  //   const worksheet = XLSX.utils.json_to_sheet(selectedRowsExportXlsx);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.utils.sheet_add_aoa(worksheet, [["Keyword"]], { origin: "A1" });
  //   XLSX.writeFile(workbook, "DataSheet.xlsx");
  // };
  

  render() {

    console.log(this.state.results)

    return (
      <>
  <Container maxWidth="xl">
      <Box sx={{ mt: 2,}}  display="flex" flexDirection="column" alignItems="center" justifyContent="center" >
    


      <form>
        <div className="wrap">
          <input
            className="searchbar"
            placeholder="Search for keywords"
            ref={input => (this.search = input)}
            onChange={this.handleInputChange}
            list="datalist"
          />
        {/* Using HTML5 Attributes datalist and options to show suggestion */ }
          
        </div>
      </form>
        <Box sx={{ ml: 35 }} id="copytoClipButton">
      <CopyToClipboard variant="contained" color="primary" text={this.state.copyClipboard} >
       <Button sx={{ mt: 3, mb: 1,  }} >Copy to clipboard</Button>
       </CopyToClipboard>
       </Box>
       {/* <Button variant="contained" color="primary" onClick={() => this.downloadExcel(this.state.selectedRowsExportXlsx)}>Selected Keywords</Button> */}

<Box width="450px" id="autocompleteData">
<DataTable columns={columns} data={this.state.results} customStyles={customStyles} selectableRows onSelectedRowsChange={this.handleChange} />
</Box>

</Box>
</Container>
</>
    );
  }
}

export default GoogleAutoSuggest;