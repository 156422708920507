import React, {useState, useEffect } from "react"
import { BallTriangle } from  'react-loader-spinner'
import {Grid, TextField, Button, Box, Container, Table, TableCell, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import axios from "axios";
var _ = require('lodash');


const submitButton = {
    paddingTop: "14px",
    paddingBottom: "14px",
    float: "right"
}

const tableHead = {
  backgroundColor: "#ddd",

}


function Allintitle() {

 const [inputValue, setinputValue] = useState();
 const [loading, setLoading] = useState();
 const [kgrKeyword, setKgrKeyword] = useState();
 const [googleKeyword, setGoogleKeyword] = useState();
 const [merge, setMerged] = useState();
 const [allintitleError, setAllintitleError] = useState(null);



 const  handleSubmit = async (e) => {
    e.preventDefault();
    


    let URL1 = "https://allintitle5.herokuapp.com/inputData"
    let URL2 = "https://keyword-intent-index.uc.r.appspot.com"
    // let URL2 = "https://kgr-seo-tool.ue.r.appspot.com"
    // https://kwintent3.herokuapp.com/inputData
    
    const promise1 = await axios.post(URL1, inputValue , {headers: {'Access-Control-Allow-Origin': '*' , 'Content-Type': 'text/plain'}});
    // const promise2 = await axios.post(URL2, inputValue , {headers: {'Access-Control-Allow-Origin': 'http://localhost:3000', 'Content-Type': 'text/plain'}});
    const promise2 = await axios.post(URL2, inputValue , {headers: {'Access-Control-Allow-Origin': '*' , 'Content-Type': 'text/plain'}});
    
     Promise.all([promise1, promise2]).then(function(values) {


      setGoogleKeyword(values[0].data);
    
      setKgrKeyword(values[1].data);
      setLoading(false);
    }).catch(error => {
      // console.log(error)
      setAllintitleError(error);
      setLoading(false);
      console.log(error);
    })

 

    } 
    useEffect(() => {
    if(googleKeyword !== undefined && kgrKeyword !== undefined){
    

      function customizer(firstValue, secondValue) {
        return Object.assign({}, firstValue, secondValue);
      }
      setMerged(_.mergeWith(kgrKeyword, googleKeyword, customizer))
    }
  }, [loading]);

    


 return (
            <>
<Container> 
    <Box>
        <Grid container  spacing={2} >
         <Grid item md={4} >
           <Box mt={4}>
         <form autoComplete="off" onSubmit={handleSubmit} > 

            <TextField id="outlined-multiline-static" label="one keyword per line" multiline rows={6}  style ={{width: '100%'}} onChange={(e) => setinputValue(e.target.value)} value={inputValue} required />

            <Box mt={2} >
                     <Button variant="contained" align="right"  color="primary"  type="submit" style={submitButton} onClick={() => setLoading(true)}>SUBMIT</Button>
              {/* </Grid> */}
          {/* </Grid> */}
          </Box>
        </form>
        </Box>
        </Grid>

        


        <Grid item md={8} mt={4}>

        {allintitleError && 
        <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '90vh' }} >
         <h2 className="errorHeading">Oops! Something went wrong</h2>
         <Box className="errorMessage" mt={5}>Please take a screenshot of <a href="https://screenful.com/how-to-open-the-browser-console" target="_blank" rel="noopener noreferrer" className="errorMessage erroranchor" >browser developer console</a> and send this to our live chat support agent</Box>
          
        </Grid>
        }


{loading ? <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '80vh' }} >
        <Grid item md={7}>
        <BallTriangle color="#FF00DF" ariaLabel="ball-triangle-loading" />
        </Grid>   
        <Grid item md={5}>
        </Grid> 
        </Grid> 
      
      : null }




       {/* { kgrKeyword !== undefined && googleKeyword !== undefined && googleKeyword && kgrKeyword ?  */}
       { googleKeyword !== ""   ? 
        <TableContainer>
      <Table aria-label="simple table">
        <TableHead style={tableHead}>
          <TableRow>
            <TableCell>Keyword</TableCell>
            <TableCell align="right">Search Volume</TableCell>
            <TableCell align="right">All in Title</TableCell>
            <TableCell align="right">KGR Ratio</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
        
              
              {/* {kgrKeyword.map(renderCell)}  */}
              {/* {googleKeyword?.map(renderCell)} */}
             
              {merge && merge.map((data, i) => (
             <TableRow key={i}>
               <TableCell>{data.text}</TableCell>
               <TableCell align="right">{data.keyword_idea_metrics.avg_monthly_searches}</TableCell>
               <TableCell align="right">{data.count}</TableCell>
              {console.log(data)}
               <TableCell align="right">{(data.count / data.keyword_idea_metrics.avg_monthly_searches).toFixed(2)}</TableCell>
               </TableRow>
             ))}
            
              
          
            
              
            
     
        </TableBody>
      </Table>
    </TableContainer>

    : null }
</Grid>
</Grid>
        </Box>
        </Container>
        

    </>
  )
}

export default Allintitle;