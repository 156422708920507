import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import mainlogo from '../Images/mainlogo.png';
import {  Link } from "react-router-dom";




const buttonStyle = {
  backgroundColor: "#FF00DF", 
  fontWeight: "bold", 
  marginTop: "16px",
  marginBottom: "16px",
  borderRadius: "30px",
  paddingLeft: "30px",
  paddingRight: "30px"
};

const imgStyle = {
  width: "50px",
  height: "50px"
}


const ResponsiveBar = ({tokens}) => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorScraperElNav, setAnchorScraperElNav] = React.useState(null);
  // const [indexElNav, setIndexElNav] = React.useState(null);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const openScraperMenu = (event) => {
    setAnchorScraperElNav(event.currentTarget);
  };
  
  const closeScraperMenu = () => {
    setAnchorScraperElNav(null);
  };

  // const openIndexMenu = (event) => {
  //   setIndexElNav(event.currentTarget);
  // };
  
  // const closeIndexMenu = () => {
  //   setIndexElNav(null);
  // };


  const handleLogout = () => {
		sessionStorage.removeItem("tawk");
    window.location.href = "https://app.keywordintent.io";
	};

  return (
    <AppBar position="static" style={{ backgroundColor: 'white' }} elevation={0} >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} /> */}
          {/* <img src={mainlogo} noWrap component="a" sx={{ display: { xs: 'none', md: 'flex' }, mr: 2 }} alt="logo" style={imgStyle} /> */}
          <Link to="/">  <Typography
            variant="h3"
            noWrap
         //   component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              color: '#FF00DF',
              textDecoration: 'none',
            }}
          >
            <img src={mainlogo} alt="logo" style={imgStyle} />

          </Typography>
</Link>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
            <Menu 
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                 <Link to="/"><Typography textAlign="center">Home</Typography> </Link> 
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                 <Link to="/kgr"><Typography textAlign="center">KGR</Typography> </Link> 
                </MenuItem>
                 <MenuItem onClick={handleCloseNavMenu}>
                 <Link to="/youtube-auto-suggest"><Typography textAlign="center">Youtube Auto Complete</Typography> </Link> 
                </MenuItem>
                 <MenuItem onClick={handleCloseNavMenu}>
                 <Link to="/reddit-topic-hunter"><Typography textAlign="center">Reddit Topic Hunter</Typography> </Link> 
                </MenuItem>

                
        
               
            </Menu>
          </Box>
          {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} /> */}
          <Link to="/">   <Typography
            variant="h3"
            noWrap
         //   component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              color: '#FF00DF',
              textDecoration: 'none',
            }}
          >
             <img src={mainlogo} alt="logo" style={imgStyle} />
          </Typography> </Link>
          
          <Box sx={{ flexGrow: 10, display: { xs: 'none', md: 'flex' } }}>
            <Box>

            {tokens ? 
          <Button style={buttonStyle} variant="contained" onClick={handleLogout}>Log out</Button>
              
           : 

            <Link to="/login">
          <Button style={buttonStyle} variant="contained">Log in</Button>
              
          </Link> }
          </Box>

              {/* Drop Down */}

              
              <Box sx={{ flexGrow: 10, display: { xs: 'none', md: 'flex' } }}>
           
             
              <Button onClick={handleOpenNavMenu} sx={{ my: 2, mx:1,  color: 'black', display: 'block' }} >Suggest</Button>
           
            <Menu 
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'none', md: 'block' },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                 <Link to="/google-suggestion"><Typography textAlign="center">Google Suggestion</Typography> </Link> 
                </MenuItem>
                <MenuItem onClick={handleCloseNavMenu}>
                 <Link to="/youtube-auto-complete"><Typography textAlign="center">Youtube Auto Complete</Typography> </Link> 
                </MenuItem>
            </Menu>


              {/* 2nd DropDown */}
             
           
             
              <Button onClick={openScraperMenu} sx={{ my: 2, mx:1.5,  color: 'black', display: 'block' }} >Premium Tools</Button>
           
            <Menu 
              id="menu-appbar"
              anchorEl={anchorScraperElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorScraperElNav)}
              onClose={closeScraperMenu}
              sx={{
                display: { xs: 'none', md: 'block' },
              }}
            >
              <MenuItem onClick={closeScraperMenu}>
                 <Link to="/quora-question-finder"><Typography textAlign="center">Quora Question Finder</Typography> </Link> 
                </MenuItem>
                <MenuItem onClick={closeScraperMenu}>
                 <Link to="/bulk-link-indexer"><Typography textAlign="center">Bulk Link indexer</Typography> </Link> 
                </MenuItem>
               <MenuItem onClick={closeScraperMenu}>
                 <Link to="/bulk-link-index-checker"><Typography textAlign="center">Bulk Link index Checker</Typography> </Link> 
                </MenuItem>
              <MenuItem onClick={closeScraperMenu}>
                 <Link to="/google-scraper"><Typography textAlign="center">Google Scraper</Typography> </Link> 
                </MenuItem>
                <MenuItem onClick={closeScraperMenu}>
                 <Link to="/bing-scraper"><Typography textAlign="center">Bing Scraper</Typography> </Link> 
                </MenuItem>
            </Menu>
          
              {/* 2nd DropDown ENd */}

            {/* 3rd DropDown */}


            {/* <Button onClick={openIndexMenu} sx={{ my: 2, mx:1,  color: 'black', display: 'block' }} >Index Checker</Button>
           
           <Menu 
             id="menu-appbar"
             anchorEl={indexElNav}
             anchorOrigin={{
               vertical: 'bottom',
               horizontal: 'left',
             }}
             keepMounted
             transformOrigin={{
               vertical: 'top',
               horizontal: 'left',
             }}
             open={Boolean(indexElNav)}
             onClose={closeIndexMenu}
             sx={{
               display: { xs: 'none', md: 'block' },
             }}
           >
             <MenuItem onClick={closeIndexMenu}>
                <Link to="/bulk-pages-index-checker"><Typography textAlign="center">Bulk Link Index Checker</Typography> </Link> 
               </MenuItem>
               <MenuItem onClick={closeIndexMenu}>
                <Link to="/bulk-website-pages-index-checker"><Typography textAlign="center">Bulk Website Pages Index checker</Typography> </Link> 
               </MenuItem>
           </Menu> */}


            {/*End 3rd DropDown */}

            <Link to="/kgr">  <Button onClick={handleCloseNavMenu} sx={{ my: 2,   color: 'black', display: 'block' }} >
                KGR Tool
              </Button>
            </Link>

            <Link to="/people-also-ask">  <Button onClick={handleCloseNavMenu} sx={{ my: 2,  color: 'black', display: 'block' }} >
                People Also Ask
              </Button>
            </Link>

            <Link to="/reddit-topic-hunter">  <Button onClick={handleCloseNavMenu} sx={{ my: 2,  color: 'black', display: 'block' }} >
                Reddit Topic Hunter
              </Button>
            </Link>

            <Link to="/">  <Button onClick={handleCloseNavMenu} sx={{ my: 2,  color: 'black', display: 'block' }} >
                Home
              </Button>
            </Link>

          </Box>


              {/* End Drop Down */}
              
            {/* <Link to="/youtube-auto-suggest">  <Button onClick={handleCloseNavMenu} sx={{ my: 2,  mx: 0.5, color: 'black', display: 'block' }} >
              Youtube Auto Complete
              </Button>
            </Link>
           
            <Link to="/kgr">  <Button onClick={handleCloseNavMenu} sx={{ my: 2,   color: 'black', display: 'block' }} >
                KGR
              </Button>
            </Link>
            <Link to="/google">  <Button onClick={handleCloseNavMenu} sx={{ my: 2,  color: 'black', display: 'block' }} >
                People Also Ask
              </Button>
            </Link>

            <Link to="/google-suggestion">  <Button onClick={handleCloseNavMenu} sx={{ my: 2,   color: 'black', display: 'block' }} >
            Google Suggestions
              </Button>
            </Link> */}
            {/* <Link to="/website-pages-index">  <Button onClick={handleCloseNavMenu} sx={{ my: 2,   color: 'black', display: 'block' }} >
            Website Pages Index
              </Button>
            </Link>

            <Link to="/bulk-pages-index">  <Button onClick={handleCloseNavMenu} sx={{ my: 2,   color: 'black', display: 'block' }} >
            Bulk Pages Index
              </Button>
            </Link> */}
        
            {/* <Link to="/bing-scraper">  <Button onClick={handleCloseNavMenu} sx={{ my: 2, color: 'black', display: 'block' }} >
              Bing Scraper
              </Button>
            </Link> */}

            
            </Box>
   
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveBar;
