import React, {useState} from 'react'
import { Grid, Box, Button, Container, TextField } from '@mui/material';
import { BallTriangle } from  'react-loader-spinner';
import "./Suggestions.css";
import DataTable from 'react-data-table-component';
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";



function GoogleSuggestion() {

 // https://kwintent-suggestions.herokuapp.com/resw

 const [inputValue, setInputValue] = useState();
 const [loading, setLoading] = useState();
 const [googleSuggest, setGoogleSuggest] = useState([]);
 const [messageerror, setMessageError] = useState(false);
 const [dataCopyToClip, setDataCopyToClip] = useState();

 const customStyles = { headCells: {
  style: {
    backgroundColor: 'rgb(255, 0, 223)',
    color: '#FFF',
    fontWeight: 'bold',
    fontSize: '17px',
  },
},
cells: {
  style: {
    fontSize: '14px',
  },
},
};

const submitButton = {
  paddingTop: "14px",
  paddingBottom: "14px",
  display: "block",
  width: "100%"
}

const  handleSubmit = async (e) => {
  e.preventDefault();
  // https://keywordintent-suggestion.herokuapp.com/resw
   await axios.post("https://google-suggestions-api.onrender.com/resw",  inputValue , {

  
      headers: {'Content-Type': 'text/plain'}
    })
      .then((response) => {
        console.log(response.data);
        setGoogleSuggest(response.data);
        setLoading(false);
        setMessageError(false)
        
      }).catch((error) => {
      
        setMessageError(true)
        setLoading(false);
      }) 
  } 


  const columns = [
    {
    name: '# ',
    cell: (row, index) => index + 1,
    width: '49px',
    },
    {
        name: 'Keywords',
        selector: row => row.split("<").shift(),
        sortable: true,
        width: '350px',
     
    }
  ];


  const handleChange = ({selectedRows }) => {

    console.log('Selected Rows: ', selectedRows);


    var arr3 = selectedRows.map(function(item){
      return item.split("<").shift() ;
    });

    setDataCopyToClip(arr3.join('\n'))
  
  };

  return (
    <>
        <Container >
      <Box sx={{ mt: 2,}}  display="flex" flexDirection="column" alignItems="center" justifyContent="center" >
    


      <form autoComplete="off" onSubmit={handleSubmit} >
      <Box width="450px" className="googleGuggestSearchBox">
      <Grid container spacing={1}>
            <Grid item xs={12} md={9} >
            <TextField required style ={{width: '100%'}} label="Enter The keyword" variant="outlined" onChange={(e) => setInputValue(e.target.value)} value={inputValue} />
      </Grid>
         
            <Grid item xs={12} md={3}>
                     <Button variant="contained" color="primary"  type="submit" style={submitButton} onClick={() => setLoading(true)}  >SUBMIT</Button>
              </Grid>
          </Grid>

        </Box>
      </form>

         <Box  sx={{alignContent: 'center'}}>
       

      <CopyToClipboard variant="contained" color="primary" text={dataCopyToClip} >
       <Button sx={{ mt: 3, mb: 1, }}  >Copy to clipboard</Button>
       </CopyToClipboard>
     
       </Box> 
       
       {messageerror && 
        <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '60vh' }} >
         <h2 className="errorHeading">Oops! Something went wrong</h2>
         <Box className="errorMessage" mt={5}>Please take a screenshot of <a href="https://screenful.com/how-to-open-the-browser-console" target="_blank" rel="noopener noreferrer" className="errorMessage erroranchor" >browser developer console</a> and send this to our live chat support agent</Box>
          
        </Grid>
        }

       {loading ? <Grid   container   spacing={0}   direction="column"   alignItems="center"   justifyContent="center"   style={{ minHeight: '80vh' }} >
        <Grid item md={7}>
        <BallTriangle color="#FF00DF" ariaLabel="ball-triangle-loading" />
        </Grid>   
        <Grid item md={5}>
        </Grid> 
        </Grid> 
      
      : null }


<Box width="450px" className="googleGuggestSearchBox" sx={{ mt: 1, mb: 1,  }}>
<DataTable columns={columns} data={googleSuggest} customStyles={customStyles} selectableRows onSelectedRowsChange={handleChange} />
</Box>

</Box>
</Container>
    </>
  )
}

export default GoogleSuggestion