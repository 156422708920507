import React from 'react';

import {List, Grid, ListItem, Container } from '@mui/material';
import {  Link } from "react-router-dom";

function Footer() {
  return (
    <footer className="footer">
        <Container>
       <Grid container py={5}>
             <Grid item xs={12} sm={6} md={3}>
                <h4 id="mozdiv1">Product</h4>
                <List sx={{ listStyleType: 'disc' }}>
                <ListItem className="footerList"> <Link to="/signup"> Sign Up </Link></ListItem>
                <ListItem className="footerList"> <Link to="/login"> Sign In </Link></ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
            <h4 id="mozdiv1">Policy</h4>
                <List sx={{ listStyleType: 'disc' }}>
                <ListItem className="footerList"> Privacy Policy</ListItem>
                <ListItem className="footerList">  Refund Policy </ListItem>
                <ListItem className="footerList">  Terms of Service</ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                 <h4 id="mozdiv1">Premium Tools</h4>

                
                <List sx={{ listStyleType: 'disc' }}>
                <ListItem className="footerList"> <Link to="/kgr">KGR Tool</Link></ListItem>
                <ListItem className="footerList"> <Link to="/reddit-topic-hunter">Reddit Topic Hunter</Link></ListItem>
                <ListItem className="footerList"> <Link to="/quora-question-finder">Quora Question Finder</Link></ListItem>
                <ListItem className="footerList"> <Link to="/bulk-link-indexer">Bulk Link indexer</Link></ListItem>
                <ListItem className="footerList"> <Link to="/bulk-link-index-checker">Bulk Link index Checker</Link></ListItem>
                {/* <ListItem className="footerList"> Chrome Extension (coming soon)</ListItem> */}
                <ListItem className="footerList"> <Link to="/google-scraper"> Google Scraper </Link></ListItem>
                <ListItem className="footerList"> <Link to="/bing-scraper"> Bing Scraper </Link></ListItem>
                </List>
            
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
            <h4 id="mozdiv1">Free Tools</h4>
                <List sx={{ listStyleType: 'disc' }}>
                <ListItem className="footerList"> <Link to="/youtube-auto-complete"> Youtube Auto Complete </Link></ListItem>
                <ListItem className="footerList"> <Link to="/"> Google Auto Complete </Link></ListItem>
                <ListItem className="footerList"> <Link to="/google-suggestion"> Google Suggestions </Link></ListItem>
                <ListItem className="footerList"> <Link to="/people-also-ask"> People Also Ask </Link></ListItem>
                </List>
            </Grid>

    </Grid>
    </Container>
    </footer>
  )
}

export default Footer