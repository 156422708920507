import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import {Typography, Button, TextField, Box, Container} from '@mui/material'

function Contact() {

    const form = useRef();

    const [message, setMessage] = useState()
console.log(message)
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_av778ji', 'template_60mbyqi', form.current, 'cDYKF9zXl4nT2SFel')
          .then((result) => {
            setMessage(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
    



  return (
      <>
     <Container maxWidth="xl">
      <Box sx={{ mt: 9,}}  display="flex" flexDirection="column" alignItems="center" justifyContent="center" >

       
       
        <Typography variant="h3" gutterBottom component="div">Contact Form</Typography>
        <Box sx={{maxWidth: '100%'}}>    
            <form ref={form} onSubmit={sendEmail}>
            <Box maxWidth="600px" display="flex" flexDirection="column" alignItems="center" justifyContent="center" >
              
          <TextField  id="outlined-basic" label="Name" variant="outlined" name="from_name" className="messsageInput" sx={{ marginTop: 2 }}  required />
       
        
          <TextField id="outlined-basic"  label="Email" variant="outlined" name="from_email" className="messsageInput" sx={{ marginTop: 2, marginBottom: 2 }} required
            // value={formValues.age}
            // onChange={handleInputChange}
          />

        <TextField id="outlined-multiline-static" label="Message" className="messsageInput" name="message" multiline rows={4} required />

        <Button variant="contained" color="primary" type="submit" className="messsageInput" sx={{  marginTop: 2, marginBottom: 2 }}>
          Submit
        </Button>
    
      </Box>
   
    </form>
    {message !== undefined &&
    <Typography variant="p" gutterBottom component="div" className="messageText" >Your message sent successfully and we will be in touch soon!</Typography> }
    </Box>
    </Box>
    </Container>
    </>
  )
}

export default Contact